import React, { Component } from "react";
//styles
import styles from "./footer.module.scss";
class footer extends Component {

    getFullYear = () => {
        const y = new Date()
        let year = y.getFullYear();
        return year;
    }

    render() {
        return (
            <footer className="grid-containerfull ">
                <br />
                <div className="grid-container">
                    <div className="grid-x grid-padding-y grid-padding-x align-self-middle">
                        <div className="small-12 medium-8 cell medium-text-left">
                            <p className={styles.copyright}>
                                Transroute Cabo Transportation  &copy;  {this.getFullYear()} - Los Cabos, Baja California
                                Sur, México
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
export default footer;
