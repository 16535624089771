import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const detectorOptions = {
    order: ['localStorage'/*, 'querystring', 'navigator', 'htmlTag'*/],
    lookupQuerystring: 'lng',
    caches: ['localStorage'],
}

i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    ns: ['holiday'],
    defaultNS: 'holiday',
    debug: false,
    fallbackLng: "en",
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    react: {
      useSuspense: true,
      wait: false,
    },
    detection: detectorOptions,
  }, function(err, t) {
    console.log('i18next:',err);
  })
export default i18next;