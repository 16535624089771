import React, { Component, Fragment, Suspense } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import "font-awesome/css/font-awesome.min.css";
import ReactTooltip from 'react-tooltip'

class TableReservations extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "ID",
                text: "ID Reserve",
                align: "center",
                sortable: true,
            },
            {
                key: "REGISTRATION_DATE",
                text: "Registered",
                align: "center",
                sortable: true,
            },
            {
                key: "AGENTE_ID",
                text: "Agent's ID",
                align: "center",
                sortable: true,
            },
            {
                key: "NAME",
                text: "Full Name",
                align: "center",
                sortable: true,
            },
            {
                key: "ARRIVAL_DATE",
                text: "Arrival",
                align: "center",
                sortable: true,
            },
            {
                key: "ARRIVAL_FLIGHT",
                text: "Flight",
                align: "center",
                sortable: true,
            },
            {
                key: "DEPARTURE_DATE",
                text: "Departure",
                align: "center",
                sortable: true,
            },
            {
                key: "TRANSPORT",
                text: "Transport",
                align: "center",
                sortable: true,
            },
            {
                key: "AUTH_CODE",
                text: "Auth Code",
                align: "center",
                sortable: true,
            },
            {
                key: "action",
                text: "Actions",
                width: 125,
                align: "center",
                className: "action",
                sortable: false,
                cell: (record) => {
                    return (
                        <Suspense>
                            <Fragment key={record.ID}>
                                <ReactTooltip id="see-reservation">
                                    <span>See Reservation</span>
                                </ReactTooltip>
                                <button
                                    data-tip
                                    data-for="see-reservation"
                                    className="btn btn-dark btn-sm"
                                    style={{ marginRight: "4px" }}
                                    onClick={() => this.props.handler(record)}
                                >
                                    <i className="fa fa-info-circle"></i>
                                </button>

                                {this.props.userlvl !== "3" ? (
                                    <>
                                        <ReactTooltip id="edit-reservation">
                                            <span>Edit Reservation</span>
                                        </ReactTooltip>
                                        <button
                                            data-tip
                                            data-for="edit-reservation"
                                            onClick={() => this.props.editHandler(record)}
                                            className="btn btn-info btn-sm"
                                            style={{ marginRight: "4px" }}
                                        >
                                            <i className="fa fa-edit"></i>
                                        </button>
                                    </>
                                ) : (
                                    null
                                    )}
                                    <ReactTooltip id="email-all-users">
                                        <span>Send voucher email to traveler and admin users</span>
                                    </ReactTooltip>
                                    <button
                                        data-tip
                                        data-for="email-all-users"
                                        onClick={() => this.props.sendReservationEmail(record, false)}
                                        className="btn btn-warning btn-sm"
                                        style={{ marginRight: "4px" }}
                                    >
                                        <i className="fa fa-paper-plane"></i>
                                    </button>
                                {
                                    this.props.userlvl === "1" ? <>
                                        <button
                                            data-tip
                                            data-for="email-to-traveler"
                                            onClick={() => this.props.sendReservationEmail(record, true)}
                                            className="btn btn-danger btn-sm"
                                            style={{ marginRight: "4px" }}
                                        >
                                            <i className="fa fa-paper-plane"></i>
                                        </button>
                                        <ReactTooltip id="email-to-traveler">
                                            <span>Send voucher email just to traveler and admin transroute</span>
                                        </ReactTooltip>

                                    </> : <div></div>
                                }
                            </Fragment>
                        </Suspense>
                    );
                },
            },
        ];

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100, 200],
            button: { excel: false },
            sort: { column: "ID_RESERVATION", order: "desc" },
        };
        this.state = {
            records: this.props.records,
        };
    }

    static getDerivedStateFromProps = (props, state) => {
        return {
            records: props.records,
        };
    };
    
    
    render() {        
         return <ReactDatatable className="table stack b-white hover table-bordered" key={"table11"} config={this.config} records={this.state.records} columns={this.columns} />;
        
    }
}

export default TableReservations;
