import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./scss/main.scss";
import { Pulse } from "./components/utils/loadings";

ReactDOM.render(
    <Suspense fallback={<Pulse/>}>
        <App />
    </Suspense>
, document.getElementById("root"));
