import axios from "axios";
import configs from "./../configs/config";
const validateToken = () => {
    const localStorage = window.localStorage;
    // console.log(localStorage);
    if (!localStorage.getItem("apitoken_rciplatform")) {
        //Si no encuentra el apitoken redirige a inicio
        window.location.href = "/";
    } else {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem("apitoken_rciplatform");
        axios
            .get(`${configs.URL_API_BASE}/verifytoken`, {})
            .then((res) => {
                if (res.data.auth == null || res.data.auth === false) {
                    localStorage.removeItem("apitoken_rciplatform");
                    localStorage.removeItem("rciplatform_userlvl");
                    localStorage.removeItem("email_user");
                    window.location.href = "/";
                }
            })
            .catch((e) => {
                console.log("error", e);
                localStorage.removeItem("apitoken_rciplatform");
                localStorage.removeItem("rciplatform_userlvl");
                localStorage.removeItem("email_user");
                localStorage.removeItem("agent_id");
                window.location.href = "/";
            });
    }
};
const handlelogout = () => {
    localStorage.removeItem("apitoken_rciplatform");
    localStorage.removeItem("rciplatform_userlvl");
    localStorage.removeItem("email_user");
    localStorage.removeItem("agent_id");
    window.location.href = "/";
};
const getUserLvl = () => {
    return localStorage.getItem("rciplatform_userlvl");
};
const getUserEmail = () => {
    return localStorage.getItem("email_user");
}
const validateUserLvl = () => {
    let lvl = localStorage.getItem("rciplatform_userlvl");
    if (lvl === "3") {
        window.location.href = "/reserve";
    }
};

export { validateToken, handlelogout, validateUserLvl, getUserLvl, getUserEmail };
