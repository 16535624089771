import React, { Component, Fragment } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import { doLogin } from "./../../api_controller/auth_controller";
export default class index extends Component {
    constructor() {
        super();
        this.state = {
            error_log: false,
            username: "",
            password: "",
            iswaiting: false,
            loading: true,
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    componentDidMount = () => {
        const localStorage = window.localStorage;
        if (localStorage.getItem("apitoken_rciplatform")) {
            window.location.href = "/reserve";
        } else {
            this.setState({ loading: false });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ iswaiting: true });

        doLogin({
            usuario: this.state.username,
            contra: this.state.password,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({ iswaiting: false });
                    console.log("X", res);
                    const localStorage = window.localStorage;
                    localStorage.setItem("apitoken_rciplatform", res.data.token);
                    localStorage.setItem("rciplatform_userlvl", res.data.results[0].NIVEL);
                    localStorage.setItem("email_user", res.data.results[0].CORREO);
                    localStorage.setItem("agent_id", res.data.results[0].AGENTE);
                    window.location.href = "/reserve";
                } else {
                    this.setState({ error_log: true });
                    this.setState({ iswaiting: false });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({ iswaiting: false });
            });
    };

    getFullYear = () => {
        const y = new Date()
        let year = y.getFullYear();
        return year;
    }

    render() {
        return (
            <Fragment>
                {this.state.loading ? (
                    <div></div>
                ) : (
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-12 col-md-7 col-lg-5 ">
                                <div className="pt-5 pb-5 mt-auto d-flex justify-content-center">
                                    <img
                                        src={process.env.PUBLIC_URL + "/logo-login.png"}
                                        className="logo-login"
                                        alt="Royal Holiday Vacation Club"
                                    />
                                </div>
                                
                                <div className="air__auth__container pl-5 pr-5 pt-5 pb-5 bg-white text-center">
                                    {this.state.error_log ? (
                                        <div className="alert alert-danger" role="alert">
                                            access denied.
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    <div className="text-dark font-size-30 mb-4">Log in</div>
                                    <form onSubmit={this.handleSubmit} className="mb-4">
                                        <div className="form-group mb-4">
                                            <label className="text-left">Username</label>
                                            <input
                                                type="text"
                                                name="username"
                                                className="form-control"
                                                placeholder="type username"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="text-left">Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder="type password"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            className="text-center btn bg-accent-color-2 w-100 font-weight-bold font-size-18">
                                            Log in
                                        </button>
                                    </form>
                                </div>
                            
                                <div className="mt-auto pb-5 pt-5 d-flex justify-content-center flex-column align-items-center">                                    
                                    <div className="text-gray-4 text-center mt-2">
                                        © {this.getFullYear()} Transroute . All rights reserved.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}
