import React, { useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { handlelogout, getUserLvl } from "../../session/session_controller";
import configs from "../../configs/config";
// import TranslationMenu from "./TranslationMenu";
import { withTranslation } from 'react-i18next';
import { tString } from '../../translations/translation';
import { Alert } from "reactstrap";

const Header = () => {

    const [userlvl] = useState(getUserLvl())
    const [emailUser] = useState(localStorage.getItem("email_user"))

    const handleClickLogOut = () => {
        handlelogout();
    };

    return (
            <div id="top-bar" className="full align-self-bottom">
                {
                    configs.ENVIRONMENT === "development" &&
                        <Alert color="danger" className="w-100 text-center">
                            Warning: Booking Reservation in Mode: <b>Testing</b>
                        </Alert>
                }
                <div className="grid-container">
                    <div className="grid-x grid-padding-x grid-padding-y">
                        <a
                            className="small-12 medium-3 large-6 cell small-only-text-center  medium-text-left large-text-left"
                            href="/reserve">
                            <img
                                className="logo-header"
                                width={'100%'}
                                src={process.env.PUBLIC_URL + "/logo-header.png"}
                                alt="Royal Holiday"
                            />
                        </a>

                        <div className="small-12 medium-9 large-6 align-self-middle text-right large-text-right">
                            <div className="grid-container">
                                <div className="grid-x grid-padding-x grid-padding-y">
                                    <div className="small-12 cell align-right">Welcome <strong>{emailUser}</strong></div>
                                    <ul className="small-12 menu align-right">
                                        <li>
                                            <a href="/reserve">
                                                {tString('holiday:topbar.Reserve')}
                                            </a>
                                        </li>
                                        {
                                            userlvl === "3" ?  null :
                                            <li>
                                                <a href="/reservations"> { tString('holiday:topbar.Reservations') }</a>
                                            </li>
                                        }                               
                                        {/* <li>
                                            <TranslationMenu />
                                        </li> */}
                                        <li>
                                            <a href="/" onClick={handleClickLogOut}>
                                                <FontAwesomeIcon icon="sign-out-alt" />  {tString('holiday:topbar.LogOut')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
       )
}

export default withTranslation()(Header);
