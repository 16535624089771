import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./components/login";
import Booking from "./components/booking/booking";
import Details from "./components/confirmation";
// import Fees from "./components/fees";
import Reservations from "./components/reservations";
import "foundation-sites/dist/css/foundation.css";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
    faPlaneArrival,
    faPlaneDeparture,
    faCalendarAlt,
    faUsers,
    faUser,
    faSuitcase,
    faRoute,
    faExclamationTriangle,
    faShuttleVan,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faPhone,
    faEnvelope,
    faPenAlt,
    faLock,
    faUserFriends,
    faFileInvoiceDollar,
    faSignOutAlt,
    faDownload,
    faClock,
    faDollarSign
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import { I18nextProvider } from 'react-i18next'
import i18next from './translations/i18n'

library.add(
    fab,
    faSignOutAlt,
    faDownload,
    faFileInvoiceDollar,
    faPlaneArrival,
    faPlaneDeparture,
    faCalendarAlt,
    faUsers,
    faSuitcase,
    faRoute,
    faExclamationTriangle,
    faShuttleVan,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faUser,
    faPhone,
    faEnvelope,
    faPenAlt,
    faLock,
    faUserFriends,
    faClock,
    faDollarSign
);

function App() {
    return (
        <Router>
            <I18nextProvider i18n={i18next}>
                <Route path="/" exact component={Login} />
                <Route path="/reserve" component={Booking} />
                <Route path="/reservations-details/:id" component={Details} />
                <Route path="/reservations" component={Reservations} />
            </I18nextProvider>
        </Router>
    );
}

export default App;
